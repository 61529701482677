var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"communicationEffect"},[_c('div',{staticClass:"download-year-report download-report",attrs:{"data-track":"增值服务-主动传播-传播效果-下载年度报告"},on:{"click":_vm.downloadYearReport}},[_c('img',{attrs:{"src":_vm.downloadReportImg,"alt":""}}),_vm._v(" 下载年度报告 ")]),(_vm.currentTask)?_c('div',{staticClass:"download-report",class:_vm.isDisabled ? 'download-report-disabled' : '',attrs:{"data-track":"增值服务-主动传播-传播效果-下载该期报告"},on:{"click":_vm.downloadReport}},[(_vm.isDisabled)?_c('img',{attrs:{"src":_vm.downloadReportDisabledImg,"alt":""}}):_c('img',{attrs:{"src":_vm.downloadReportImg,"alt":""}}),_vm._v(" 下载该期报告 ")]):_vm._e(),_c('commonTable',{ref:"taskList",attrs:{"table-data":_vm.taskTableData,"column-data":_vm.taskColumn,"init-obj":_vm.taskPageObj,"show-list-btn":"","no-serial":"","show-choose":"","is-click":""},on:{"onRowClick":_vm.handleRowClick,"onChangePageData":(pageObj) => {
        _vm.getTaskList(pageObj)
      }}}),_c('div',{staticClass:"article-reading mb-20 mt-20"},[_c('div',{staticClass:"first-title"},[_vm._v("文章阅读情况")]),_vm._m(0),_c('commonTable',{attrs:{"table-data":_vm.articleTableData,"column-data":_vm.articleColumn,"init-obj":_vm.articlePageObj,"show-handle":"","handle-text":"阅读详情","handle-content":"详情"},on:{"onChangePageData":(pageObj) => {
          _vm.getArticleListData(pageObj)
        },"onHandleClick":_vm.openArticleDetails}}),(_vm.showWZYDQK)?_c('div',[_vm._m(1),_c('div',{style:({
          width: '100%',
          height: '420px'
        }),attrs:{"id":"chartWZYDQK"}})]):_vm._e()],1),(_vm.showGWFW)?_c('div',{staticClass:"mb-20"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("推送前后官网访问量")]),_c('div',{style:({
        width: '100%',
        height: '420px'
      }),attrs:{"id":"chartGWFW"}})]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title table-title"},[_c('span',[_vm._v("文章阅读量排行")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("推送前后单篇文章阅读情况")])])
}]

export { render, staticRenderFns }