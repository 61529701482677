<template>
  <div v-loading="loading" class="communicationEffect">
    <div
      class="download-year-report download-report"
      @click="downloadYearReport"
      data-track="增值服务-主动传播-传播效果-下载年度报告"
    >
      <img :src="downloadReportImg" alt="" />
      下载年度报告
    </div>
    <div
      v-if="currentTask"
      class="download-report"
      @click="downloadReport"
      data-track="增值服务-主动传播-传播效果-下载该期报告"
      :class="isDisabled ? 'download-report-disabled' : ''"
    >
      <img v-if="isDisabled" :src="downloadReportDisabledImg" alt="" />
      <img v-else :src="downloadReportImg" alt="" />
      下载该期报告
    </div>
    <commonTable
      ref="taskList"
      :table-data="taskTableData"
      :column-data="taskColumn"
      :init-obj="taskPageObj"
      show-list-btn
      no-serial
      show-choose
      is-click
      @onRowClick="handleRowClick"
      @onChangePageData="
        (pageObj) => {
          getTaskList(pageObj)
        }
      "
    />
    <!-- 文章阅读情况 -->
    <div class="article-reading mb-20 mt-20">
      <div class="first-title">文章阅读情况</div>
      <!-- table表格 -->
      <div class="second-title table-title">
        <span>文章阅读量排行</span>
      </div>
      <commonTable
        :table-data="articleTableData"
        :column-data="articleColumn"
        :init-obj="articlePageObj"
        show-handle
        handle-text="阅读详情"
        handle-content="详情"
        @onChangePageData="
          (pageObj) => {
            getArticleListData(pageObj)
          }
        "
        @onHandleClick="openArticleDetails"
      />
      <!-- echarts图 -->
      <div v-if="showWZYDQK">
        <div class="second-title"><span>推送前后单篇文章阅读情况</span></div>
        <div
          id="chartWZYDQK"
          :style="{
            width: '100%',
            height: '420px'
          }"
        />
      </div>
    </div>
    <!-- 推送前后官网访问量 -->
    <div v-if="showGWFW" class="mb-20">
      <div class="first-title mb-20">推送前后官网访问量</div>
      <div
        id="chartGWFW"
        :style="{
          width: '100%',
          height: '420px'
        }"
      />
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import commonTable from '@comp/commonTable'
import echarts from 'echarts'
import { mapState } from 'vuex'
import { getWeChatType } from '@assets/js/utils.js'
const api = ProjectConfig.communicationEffectStatistics
export default {
  name: 'CommunicationEffect',
  components: {
    commonTable
  },
  data() {
    return {
      loading: true,
      downloadReportImg: require('@assets/img/download-report.png'),
      downloadReportDisabledImg: require('@assets/img/download-report-disabled.png'),
      taskId: '',
      articleTableData: [], // 推送前后单篇文章阅读情况
      articleColumn: [
        {
          title: '标题',
          prop: 'topic',
          special: true,
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '年/卷/期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量（邮件）',
          prop: 'emailClickCount',
          width: '120px',
          notSortable: true,
          tooltip: true,
          tooltipContent:
            '阅读量（邮件）：截至当前时间，点击推送邮件中链接来阅读文章的次数'
        },
        {
          title: '阅读量（微信）',
          prop: 'wechatClickCount',
          width: '120px',
          notSortable: true,
          tooltip: true,
          tooltipContent: '阅读量（微信）：截至当前时间，在微信中阅读文章的次数'
        },
        {
          title: '阅读量（官网）',
          prop: 'clickCount',
          width: '120px',
          notSortable: true,
          tooltip: true,
          tooltipContent:
            '阅读量（官网）：截至当前时间，在官网显示的HTML、PDF、摘要三者阅读量之和'
        }
      ],
      articlePageObj: {
        page: 1,
        size: 10
      },
      GWFWdata: [],
      WZYDQKdata: [],
      leftPushlishTime: '',
      rightPushlishTime: '',
      taskPageObj: {
        page: 1,
        size: 10
      },
      taskTableData: null, // 推送任务列表
      taskColumn: [
        {
          title: '内容类型',
          prop: 'handleType',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'year',
          notSortable: true,
          width: '80px'
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true,
          width: '80px'
        },
        {
          title: '邮件主题',
          prop: 'historyTopic',
          notSortable: true
        },
        {
          title: '邮件推送时间',
          prop: 'historyPushTime',
          notSortable: true
        },
        {
          title: '微网刊标题',
          prop: 'sysTopic',
          notSortable: true
        },
        {
          title: '微网刊发布时间',
          prop: 'publishTime',
          notSortable: true,
          edit: true,
          notShowInput: true
        }
      ],
      currentTask: '',
      networkStartVisitLegend: false,
      startVisitLegend: false,
      endVisitLegend: false
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    isDisabled() {
      return this.currentTask.year < 2021
      // return this.currentTask.type == 2 || this.currentTask.type == 3
    },
    showWZYDQK() {
      let haveData = false
      if (
        this.WZYDQKdata &&
        this.WZYDQKdata.pushArticleVisitMapList &&
        this.WZYDQKdata.pushArticleVisitMapList.length > 1
      ) {
        this.WZYDQKdata.pushArticleVisitMapList.map((item) => {
          if (
            item.startVisitCount > 0 ||
            item.endVisitCount ||
            item.networkStartVisitCount
          ) {
            haveData = true
          }
        })
      }
      return haveData
    },
    showGWFW() {
      let haveData = false
      if (
        this.GWFWdata &&
        this.GWFWdata.sumVisitCountList &&
        this.GWFWdata.sumVisitCountList.length > 1
      ) {
        this.GWFWdata.sumVisitCountList.map((item) => {
          if (item.sumVisitCount > 0) {
            haveData = true
          }
        })
      }
      return haveData
    }
  },
  watch: {
    magId(n) {
      if (n) {
        this.$nextTick(() => {
          this.getTaskList()
        })
      } else {
        this.$nextTick(() => {
          this.initData()
        })
      }
    },
    currentTask() {
      this.$nextTick(() => {
        this.getData()
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  mounted() {
    if (this.magId) {
      this.$nextTick(() => {
        this.getTaskList()
      })
    } else {
      this.loading = false
    }
    window.addEventListener('resize', this.adjustTable)
  },
  methods: {
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('chartGWFW')) {
        echarts.init(document.getElementById('chartGWFW')).resize()
      }
      if (document.getElementById('chartWZYDQK')) {
        echarts.init(document.getElementById('chartWZYDQK')).resize()
      }
    },
    initData() {
      this.articleTableData = []
      this.chartGWFW()
      this.chartWZYDQK()
    },
    // 列表获取
    getTaskList(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId
      }
      let url = api.GetTaskList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.taskPageObj = {
            page,
            size,
            total: totalElements
          }
          this.taskTableData = content
          if (this.taskTableData && this.taskTableData.length > 0) {
            this.taskTableData.map((item) => {
              item.handleType = getWeChatType(item.type)
              item.publishTime = item.publishDate
                ? item.publishDate
                : item.sysCreated
                ? item.sysCreated
                : ''
            })
            this.$nextTick(() => {
              this.$refs.taskList.handleRowClick(this.taskTableData[0])
            })
          }
        }
      })
    },
    // 点击每一行
    handleRowClick(id, item) {
      this.currentTask = item
      this.networkStartVisitLegend = false
      this.startVisitLegend = false
      this.endVisitLegend = false
    },
    getData() {
      let params = {
        magId: this.magId,
        type: this.currentTask.type,
        year: this.currentTask.year,
        issue: this.currentTask.issue || ''
      }
      // 获取官网访问echarts数据
      this.getChartData('GWFW', params, api.GetSumVisitCount.url)
      // 推送前后单篇文章阅读情况
      this.getChartData('WZYDQK', params, api.GetVisitCount.url)
      this.getArticleListData()
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          let _this = this
          if (type === 'WZYDQK') {
            _this.WZYDQKdata = data
            if (_this.showWZYDQK) {
              _this.$nextTick(() => {
                _this.chartWZYDQK(data)
              })
            }
          } else if (type === 'GWFW') {
            _this.GWFWdata = data
            if (_this.showGWFW) {
              _this.$nextTick(() => {
                _this.chartGWFW(data)
              })
            }
          }
        }
      })
    },
    // 官网访问
    chartGWFW(data) {
      if (!document.getElementById('chartGWFW')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartGWFW'))
      const timeArr = []
      const countArr = []
      let _this = this
      if (data && data.sumVisitCountList.length > 0) {
        let emailPublishTime = data.emailPublishTime
        let wechatPublishTime = data.wechatPublishTime
        this.leftPushlishTime =
          data.emailPublishTime > data.wechatPublishTime
            ? data.wechatPublishTime
            : data.emailPublishTime
        this.rightPushlishTime =
          data.wechatPublishTime > data.emailPublishTime
            ? data.wechatPublishTime
            : data.emailPublishTime
        data.sumVisitCountList.map((item) => {
          if (
            emailPublishTime === wechatPublishTime &&
            item.crawlTime === emailPublishTime
          ) {
            timeArr.push(item.crawlTime + ' 邮件推送微网刊发布')
          } else if (item.crawlTime === emailPublishTime) {
            timeArr.push(item.crawlTime + ' 邮件推送')
          } else if (item.crawlTime === wechatPublishTime) {
            timeArr.push(item.crawlTime + ' 微网刊发布')
          } else {
            timeArr.push(item.crawlTime)
          }
          countArr.push(item.sumVisitCount)
        })
      }
      myChart.setOption(
        {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: timeArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 15) {
                    res = res.substring(0, 15) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '17%',
            top: '4%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '文献访问总量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  color: (params) => {
                    // 微网刊推送前 邮件推送后 及中间的部分 分别用三部分颜色标识
                    if (params.name.split(' ')[0] < _this.leftPushlishTime) {
                      return '#65A085'
                    } else if (
                      !(params.name.split(' ')[0] < _this.rightPushlishTime)
                    ) {
                      return '#265cca'
                    } else {
                      return '#BBE0E3'
                    }
                  },
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 推送前后单篇文章阅读情况echarts图
    chartWZYDQK(data) {
      if (!document.getElementById('chartWZYDQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartWZYDQK'))
      const titleArr = []
      const startVisitCountArr = []
      const endVisitCountArr = []
      const networkStartVisitCountArr = []
      let XbarWidth = null
      if (data && data.pushArticleVisitMapList.length > 0) {
        data.pushArticleVisitMapList.map((item) => {
          item.title = item.title.replace(/<[^>]+>/g, '')
          titleArr.push(item.title)
          startVisitCountArr.push(item.startVisitCount || 0)
          endVisitCountArr.push(item.endVisitCount || 0)
          networkStartVisitCountArr.push(item.networkStartVisitCount || 0)
        })
      }
      networkStartVisitCountArr.map((item) => {
        if (item !== 0) {
          this.networkStartVisitLegend = true
        }
      })
      startVisitCountArr.map((item) => {
        if (item !== 0) {
          this.startVisitLegend = true
        }
      })
      endVisitCountArr.map((item) => {
        if (item !== 0) {
          this.endVisitLegend = true
        }
      })
      XbarWidth = titleArr.length >= 15 ? '' : 20
      let _this = this
      myChart.setOption(
        {
          color: ['#65A085', '#BBE0E3', '#265cca'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['微网刊发布前', '邮件推送前', '当前阅读'],
            selected: {
              微网刊发布前: _this.networkStartVisitLegend,
              邮件推送前: _this.startVisitLegend,
              当前阅读: _this.endVisitLegend
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '20%',
            top: '4%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '微网刊发布前',
              type: 'bar',
              data: networkStartVisitCountArr,
              barWidth: XbarWidth,
              barMaxWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '邮件推送前',
              type: 'bar',
              data: startVisitCountArr,
              barWidth: XbarWidth,
              barMaxWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '当前阅读',
              type: 'bar',
              data: endVisitCountArr,
              barWidth: XbarWidth,
              barMaxWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 推送前后单篇文章阅读情况table表格
    getArticleListData(pageParams = {}) {
      this.articleTableData = []
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId,
        type: this.currentTask.type,
        year: this.currentTask.year,
        issue: this.currentTask.issue || ''
      }
      let url = api.ArticleClickRankList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            const { content = [], totalElements = 0 } = data
            this.articlePageObj = {
              page,
              size,
              total: totalElements
            }
            this.articleTableData = content
          }
        }
      })
    },
    openArticleDetails(item) {
      let routeData = this.$router.resolve({
        path: 'details',
        query: {
          columnId: this.$route.query.columnId,
          articleId: item.docId,
          taskId: this.currentTask.historyId
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 下载该期报告
    downloadReport() {
      if (this.isDisabled) {
        return
      }
      let routeData = this.$router.resolve({
        path: 'analysisReport',
        query: {
          columnId: this.$route.query.columnId,
          taskId: this.currentTask.historyId,
          networkId: this.currentTask.networkId,
          type: this.currentTask.type,
          year: this.currentTask.year,
          issue: this.currentTask.issue
        }
      })
      window.open(routeData.href, '_blank')
    },
    downloadYearReport() {
      let routeData = this.$router.resolve({
        path: 'analysisYearReport',
        query: {
          columnId: this.$route.query.columnId
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang="scss">
.communicationEffect {
  .el-cascader {
    width: 260px;
  }
  .list-btn {
    height: 20px;
    line-height: 20px !important;
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss" scoped>
.communicationEffect {
  padding: 20px;
  .download-year-report {
    right: 260px !important;
  }
  .download-report {
    display: block;
    position: absolute;
    right: 120px;
    font-size: 14px;
    cursor: pointer;
    color: #2644ca;
    display: flex;
    align-items: center;
    margin-top: -3px;
    img {
      width: 25px;
      height: 25px;
      margin-right: 6px;
    }
  }
  .download-report-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }
  .article-reading {
    .table-title {
      margin-top: 20px;
    }
    .conter_3 {
      margin: 0 !important;
    }
  }
}
</style>
